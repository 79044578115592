import React, {useState, useEffect} from 'react'
import fetchAPI from 'src/lib/utils/fetch-api'
import {
    IFilterOption,
    IOrdersByManager,
    IOrdersByManagerColumns,
    IOrdersByMonth, IOrdersByMonthColumns,
    OrderItemResponse
} from "../types/types";
import {toCapitalize} from "../../../../utilits/toCapitalize";
import {isValidOrderByManager} from "../lib/isValidOrderByManager";
import dayjs from "dayjs";
import {isValidCO} from "../lib/isValidCO";
import {useSelector} from "react-redux";
import {createSelector} from 'reselect';
import {formatPrice} from "../../../utils";
import {manyTablesData} from "../selectors/manyTablesData";
import {oneTableRender} from "../selectors/oneTableRender";
import {isValidCoContract} from "../lib/isValidCoContract";


const createSession = createSelector(
    (state: any) => state.session,
    sessionData => sessionData,
)


export const useFetchData = (
    selectedYears: string[],
    selectedMonths: string[],
    selectedOrganizations: string[],
    selectedOffices: string[],
    selectedBrands: string[],
) => {
    const {sessionData} = useSelector(createSession);

    const [years, setYears] = useState<IFilterOption[]>([])
    const [organizations, setOrganizations] = useState<IFilterOption[]>([])
    const [offices, setOffices] = useState<IFilterOption[]>([])
    const [brands, setBrands] = useState<IFilterOption[]>([])

    const [ordersByManager, setOrdersByManager] = useState<IOrdersByManager>()
    const [ordersByManagerColumns, setOrdersByManagerColumns] = useState<IOrdersByManagerColumns>([])

    const [ordersByMonth, setOrdersByMonth] = useState<IOrdersByMonth>()
    const [ordersByMonthColumns, setOrdersByMonthColumns] = useState<IOrdersByMonthColumns>([])

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false)


    useEffect(() => {
        loadFilterData()
    }, [])

    useEffect(() => {
        if (initialLoadComplete) {
            loadData()
        }
    }, [
        selectedYears,
        selectedMonths,
        selectedOrganizations,
        selectedOffices,
        selectedBrands,
        initialLoadComplete,
    ])

    const loadFilterData = async () => {
        setIsLoading(true)
        try {
            const result = await fetchAPI('/api/order_report/api/filters', {
                headers: {
                    Authorization: 'Basic ' + btoa('zeokna:1q55CVboYQ'),
                },
            })
            const {years, organizations, offices, brands} = result
            setYears(years)
            setOrganizations(organizations)
            setOffices(offices)
            setBrands(brands)
        } catch (e) {
            console.log(e)
        } finally {
            setInitialLoadComplete(true)
        }
    }

    const loadData = async () => {
        setIsLoading(true)
        const filters: any = {
            years: selectedYears.join(','),
            months: selectedMonths.join(','),
            organizations: selectedOrganizations.join(','),
            offices: selectedOffices.join(','),
            brands: selectedBrands.join(','),
        }

        const queryString = new URLSearchParams(filters).toString();

        try {
            let url = `/api/order_report/api?${queryString}`
            const data = await fetchAPI(url, {
                headers: {
                    Authorization: 'Basic ' + btoa('zeokna:1q55CVboYQ'),
                },
            }) || []


            const ordersByManagerTmp = {};
            const ordersByMonthTmp = {};
            const unIdsCO = new Set();
            const unIdsPrepayment = new Set();
            const ordersPrepayment = {};

            data.forEach((item: OrderItemResponse) => {
                const date = new Date(item?.['order-date-fact']);
                const month = date.toLocaleString('ru-RU', {month: 'long', timeZone: 'UTC'});
                const year = date.getFullYear().toString()
                const key = `${toCapitalize(month) + year}`;

                if (isValidOrderByManager(item)) {
                    const order = {
                        orderDate: item['prepayment_date'],
                        caseDate: item['case-date-fact'],
                        contractNum: item['production-order-number'],
                        sum: item['contract-sum'],
                        manager: item["manager-name"],
                        days: item['prepayment_date'] ? dayjs(item['prepayment_date']).diff(item['case-date-fact'], "day") : 1,
                        key: item['order-date-fact']
                    }

                    if (ordersByManagerTmp?.[key]) {
                        if (ordersByManagerTmp[key][item["manager-name"]]) {
                            ordersByManagerTmp[key][item["manager-name"]].push(order)
                        } else {
                            ordersByManagerTmp[key][item["manager-name"]] = [order]
                        }
                    } else {
                        ordersByManagerTmp[key] = {}
                        ordersByManagerTmp[key][item["manager-name"]] = [order]
                    }
                }

                if (isValidCoContract(item, selectedMonths, selectedYears)) {
                    if (!unIdsPrepayment.has(item["parent-id"])) {
                        if (ordersPrepayment?.[key]) {
                            if (ordersPrepayment[key][item["manager-name"]]) {
                                ordersPrepayment[key][item["manager-name"]] += 1
                            } else {
                                ordersPrepayment[key][item["manager-name"]] = 1
                            }
                        } else {
                            ordersPrepayment[key] = {}
                            ordersPrepayment[key][item["manager-name"]] = 1
                        }

                        unIdsPrepayment.add(item["parent-id"])
                    }
                }

                if (isValidCO(item, selectedMonths, selectedYears)) {
                    if (!unIdsCO.has(item["parent-id"])) {
                        if (ordersByMonthTmp?.[key]) {
                            if (ordersByMonthTmp[key][item["manager-name"]]) {
                                ordersByMonthTmp[key][item["manager-name"]].push(item)
                            } else {
                                ordersByMonthTmp[key][item["manager-name"]] = [item]
                            }
                        } else {
                            ordersByMonthTmp[key] = {}
                            ordersByMonthTmp[key][item["manager-name"]] = [item]
                        }

                        unIdsCO.add(item["parent-id"])
                    }
                }
            })

            const monthKeys = Object.keys(ordersByMonthTmp);
            const isOneTableRender = monthKeys.length <= 3;

            const ordersByMonthResult = isOneTableRender
                ? oneTableRender(ordersByMonthTmp,ordersPrepayment, monthKeys, selectedMonths, selectedYears)
                : manyTablesData(ordersByMonthTmp,ordersPrepayment, monthKeys, selectedMonths, selectedYears);

            console.log(ordersByMonthTmp)
            console.log(ordersPrepayment)

            setOrdersByManagerColumns([
                {
                    title: 'Менеджер',
                    dataIndex: 'manager',
                    key: 'manager',
                    sorter: {
                        compare: (a, b) => a.manager.localeCompare(b.manager),
                        multiple: 4,
                    },
                },
                {
                    title: 'Дата оплаты',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    sorter: {
                        //@ts-ignore
                        compare: (a, b) => new Date(a.orderDate) - new Date(b.orderDate),
                        multiple: 1,
                    },
                    render: (v) => v ? dayjs(v).format('DD/MM/YY') : 'не установлена',
                },
                {
                    title: 'Дата заявки',
                    dataIndex: 'caseDate',
                    key: 'caseDate',
                    sorter: {
                        //@ts-ignore
                        compare: (a, b) => new Date(a.caseDate) - new Date(b.caseDate),
                        multiple: 2,
                    },
                    render: v => v ? dayjs(v).format('DD/MM/YY') : 'не установлена'
                },
                {
                    title: 'Договор',
                    dataIndex: 'contractNum',
                    key: 'contractNum'
                },
                {
                    title:
                        'Сделка, дни',
                    dataIndex: 'days',
                    key: 'days',
                    sorter: {
                        compare: (a, b) => a.days - b.days,
                        multiple: 3,
                    },
                },
                {
                    title: 'Продажи',
                    dataIndex: 'sum',
                    key: 'sum',
                    sorter: {
                        compare: (a, b) => a.sum - b.sum,
                        multiple: 5,
                    },
                    render: (v) => formatPrice(v)
                },
            ])
            setOrdersByMonthColumns([
                {
                    title: 'Менеджер',
                    dataIndex: 'manager',
                    key: 'manager',
                    sorter: {
                        compare: (a, b) => a.manager.localeCompare(b.manager),
                        multiple: 1,
                    },
                },
                (sessionData.roles.includes(19) && {
                    title: 'ЦО',
                    dataIndex: 'co',
                    key: 'co',
                    sorter: {
                        compare: (a, b) => a.co - b.co,
                        multiple: 2,
                    },
                }),
                {
                    title: 'Отказы до КП',
                    dataIndex: 'cancel_before_kp',
                    key: 'cancel_before_kp',
                    sorter: {
                        compare: (a, b) => a.cancel_before_kp - b.cancel_before_kp,
                        multiple: 3,
                    },
                },
                {
                    title: 'Отказы после КП',
                    dataIndex: 'cancel_after_kp',
                    key: 'cancel_after_kp',
                    sorter: {
                        compare: (a, b) => a.cancel_after_kp - b.cancel_after_kp,
                        multiple: 4,
                    },
                },
                {
                    title: 'Заказы в работе',
                    dataIndex: 'workOrders',
                    key: 'workOrders',
                    sorter: {
                        compare: (a, b) => a.workOrders - b.workOrders,
                        multiple: 5,
                    },
                },
                {
                    title: 'Замеров выполнено',
                    dataIndex: 'measurements',
                    key: 'measurements',
                    sorter: {
                        compare: (a, b) => a.measurements - b.measurements,
                        multiple: 6,
                    },
                },
                {
                    title: 'Договоры по заявкам этого месяца',
                    dataIndex: 'contracts',
                    key: 'contracts',
                    sorter: {
                        compare: (a, b) => a.contracts - b.contracts,
                        multiple: 9,
                    },
                },
                {
                    title: '% лид/дог',
                    dataIndex: 'percent_ld',
                    key: 'percent_ld',
                    sorter: {
                        compare: (a, b) => a.percent_ld - b.percent_ld,
                        multiple: 7,
                    },
                    render: (v) => typeof v === "number" ? v?.toFixed(2) + '%' : v
                },
                {
                    title: '% зам/дог',
                    dataIndex: 'percent_md',
                    key: 'percent_md',
                    sorter: {
                        compare: (a, b) => a.percent_md - b.percent_md,
                        multiple: 8,
                    },
                    render: (v) => typeof v === "number" ? v?.toFixed(2) + '%' : v
                },
                {
                    title: '% ЦО/дог',
                    dataIndex: 'percent_cc',
                    key: 'percent_cc',
                    sorter: {
                        compare: (a, b) => a.percent_cc - b.percent_cc,
                        multiple: 10,
                    },
                    render: (v) => typeof v === "number" ? v?.toFixed(2) + '%' : v
                },
                {
                    title: 'СЧ продаж',
                    dataIndex: 'average_score',
                    key: 'average_score',
                    sorter: {
                        compare: (a, b) => a.average_score - b.average_score,
                        multiple: 11,
                    },
                     render: (v) => v ? formatPrice(v) : ''
                },
            ])
            setOrdersByManager(ordersByManagerTmp)
            setOrdersByMonth(ordersByMonthResult)
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }


    const refetchData = () => {
        loadData()
    }

    return {
        ordersByManager,
        ordersByManagerColumns,
        ordersByMonth,
        ordersByMonthColumns,
        years,
        organizations,
        offices,
        brands,
        isLoading,
        refetchData,
    }
}
